import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import ContactTile from "../components/contact/contact-tile"
import TextWithOrWithoutGallery from "../components/dynamic-page-components/text-with-or-without-gallery/text-with-or-without-gallery"
import { GalleryPictureItem } from "../components/gallery/model/gallery-item"
import BrandPreview from "../components/generic-preview/brand-preview/brand-preview"
import MunzingLocationMap from "../components/map/munzingLocationMap"
import OverviewGrid from "../components/overview-grid/overview-grid"
import OverviewSlider from "../components/overview-slider/overview-slider"
import SEO from "../components/seo"
import Tile from "../components/tile/tile"
import SideNavView from "../components/views/side-nav-view/side-nav-view"
import useBrands from "../hooks/useBrands"
import { useTranslation } from "../i18n/translate"
import { MunzingLocationModel } from "../model/munzingLocationModel"

const MunzingLocation = ({ data, location }) => {
  const munzingLocation = new MunzingLocationModel(data.strapiMunzingLocations)

  const { t } = useTranslation()
  const brands = useBrands().filter((brand) =>
    munzingLocation.brandIds.includes(brand.id.toString()),
  )

  const gallery = []

  if (munzingLocation.image_resources) {
    for (const { image, description } of munzingLocation.image_resources) {
      gallery.push(
        new GalleryPictureItem({
          gatsbyFluidImage: image.localFile.childImageSharp.gatsbyImageData,
          descriptionTranslationKey: description ? description.key : "",
        }),
      )
    }
  }

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t(munzingLocation.name.key)}
        descriptionTranslatedString={t(munzingLocation.description.key)}
      />
      <SideNavView
        titleTranslatedString={t(munzingLocation.name.key)}
        pathName={location.pathname}
      >
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-12">
            <Tile
              titleTranslatedString={t(
                "MUNZING_GROUP-MUNZING_WORLDWIDE-LOCATIONS-ABOUT_THIS_LOCATION",
              )}
            >
              {munzingLocation.description && (
                <TextWithOrWithoutGallery
                  gallery={gallery}
                  textTranslationKey={munzingLocation.description.key}
                />
              )}
            </Tile>
          </div>

          {munzingLocation.matterportId && (
            <div className="_fp-col-12">
              <Tile tileSize={"xs"}>
                <a
                  target={"_blank"}
                  style={{ display: "block" }}
                  href={`https://my.matterport.com/show/?m=${munzingLocation.matterportId}`}
                >
                  <img
                    style={{
                      width: "100%",
                      display: "block",
                      borderRadius: "10px",
                      aspectRatio: "853/480",
                    }}
                    alt="Virtual 3D-Tour"
                    src={"/images/matterport-tour-thumbnail.jpg"}
                  ></img>
                </a>
              </Tile>
            </div>
          )}

          {munzingLocation.contacts.length > 0 && (
            <div className="_fp-col-12">
              <Tile titleTranslatedString={t("GLOBAL-CONTACT")} noTile={true}>
                <OverviewGrid>
                  {munzingLocation.contacts.map((contact) => {
                    return <ContactTile contact={contact} />
                  })}
                </OverviewGrid>
              </Tile>
            </div>
          )}

          <div className="_fp-col-12">
            <Tile tileSize={"xs"}>
              <MunzingLocationMap locations={[munzingLocation]} />
            </Tile>
          </div>

          {brands.length > 0 && (
            <>
              <div className="_fp-col-12">
                <OverviewSlider
                  title={t(
                    "MUNZING_GROUP-MUNZING_WORLDWIDE-LOCATIONS-BRANDS_PRODUCED_HERE",
                  )}
                  linkPath={"/products/"}
                  linkTextTranslatedString={t("BRANDS-VIEW_ALL_BRANDS")}
                >
                  {brands
                    .filter((brand) => !brand.hidden)
                    .sort((a, b) => {
                      return t(a.name.key).localeCompare(t(b.name.key))
                    })
                    .map((brand) => {
                      return (
                        <BrandPreview brand={brand} key={`key_${brand.id}`} />
                      )
                    })}
                </OverviewSlider>
              </div>
            </>
          )}
          <div className="_fp-col-12"></div>
        </div>
      </SideNavView>
    </>
  )
}

export default MunzingLocation

export const query = graphql`
  query ($locationId: Int) {
    strapiMunzingLocations(strapiId: { eq: $locationId }) {
      matterport_id
      description {
        key
      }
      brands {
        id
      }
      name {
        key
      }
      nav_item {
        uid
        url
        name {
          key
        }
      }
      strapiId
      image_resources {
        description {
          key
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      contacts {
        ...ContactParams
      }
      location {
        ...GoogleMapsLocationParams
      }
    }
  }
`
